import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Col, Container, Image, Row } from "react-bootstrap"
import Meta from "../components/Meta"

export default function defaultTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark
  return (

    <Layout>
      <Meta title={frontmatter.title} metaTitle={frontmatter.metaTitle} metaDescription={frontmatter.metaDescription} />
      <Container className="text-center">
        <Row>
          <Col>
            <h1 className="py-4">{frontmatter.title}</h1>
          </Col>
        </Row>
      </Container>

      <Container >
        <Row className="text-center">
          <Col>
            <Image src={"/" + frontmatter.image} alt={frontmatter.altImage} fluid />
          </Col>
        </Row>
        <Row className="my-2 pt-1">
          <Col>
            <h2>{frontmatter.title}</h2>
          </Col>
        </Row>
        <Row>
          <Col dangerouslySetInnerHTML={{ __html: frontmatter.content }}>
          </Col>
        </Row>
      </Container>
      {frontmatter.date ? <Container><Row>
        <Col className="text-right"><time datetime={frontmatter.date}>Aggiornato il {frontmatter.date}</time></Col></Row>
      </Container> : ''}
    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        bannerTitle
        title
        content
        altImage
        image
        metaTitle
        metaDescription
        section {
          id
          title
          body
          link
          linkLabel
        }
      }
    }
}
`
